import Monogatari from "@monogatari/core";
import {firebaseAnalytics} from "../Firebase/firebaseConfig";

export function changeAlertName(){
  document.querySelector("#monogatari > visual-novel > alert-modal > div > p").textContent('Please change')
}

export function recordEvent(pagename){
  firebaseAnalytics.logEvent(pagename)
};

function hideTextbox(){
    document.querySelector ('text-box').style.display="none"
  }

function updateTime(filename){
  const url = "./assets/images/"+ filename +".png";
  document.getElementById("clock_img").src=url;
};

function showCreditButton(){
  document.getElementById("game-credit-p").style.display="block"
  document.getElementById("sidemenu").style.animation="fadeOut 1s forwards"
  document.getElementById("clock").style.display="none"
}

function hideClock(){
  document.getElementById("clock").style.animation="fadeOut 1s forwards"
}


function changeBackgroundcolor(){
  document.querySelector("#background").style.backgroundColor="#C4C4C4"
}

Monogatari.script({
  Start: [
    function(){recordEvent("story_1_page_1_visited")},
    function(){hideTextbox()},
    function(){updateTime(600)},
    "show scene board1_0A with fadeIn",
    "wait 300",
    "play sound alarm-clock",
    "wait 3000",
    // "show scene board1_0B with fadeIn",
    "show image hand-background with fadeIn fullsize",
    "show image hand with fadeIn fullsize hand-animation",
    "play voice vo_01",
    "6AM?! I am so late. We only have 15 minutes to get ready and leave the house! Is Mei even awake yet? <br> (in a slightly louder voice) Mei, wake up! <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_01",
    "stop sound alarm-clock",   
    function(){updateTime(610)},
    function(){recordEvent("story_1_page_2_visited")},
    "show scene board1_1 with fullsize",
    "play voice vo_02",
    "Mei, hurry! Grab your breakfast, we have to go now!<br>At least Mei has something to eat. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_02",
    function(){updateTime(615)},
    function(){recordEvent("story_1_page_3_visited")},
    "show scene board1_2 with board-animate",
    "play voice vo_03",
    "show image plant_women with plant-animate",
    "show image kid with kid-animate",
    "play sound quick-footsteps with fade 10",
    "play sound watering with volume 40",
    "Hi Auntie Faridah! (waves frantically)<br>(yikes) Come on Mei, we are late. Faster! <img id='text-icon' src='./assets/images/end text icon.png'/>",
    function(){recordEvent("story_1_page_4_visited")},
    "show scene board1_3A with fadeIn",
    "stop sound quick-footsteps",
    "stop sound watering",
    "stop voice vo_03",
    "play sound elevator-opening",
    "wait 300",
    "play voice vo_04",
    "I am so tired. If only I can sleep in, play football and study more... so I can get into my dream school. But Ma and Pa works day and night, I have to take care of Mei and the house. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "play sound elevator-close",
    function(){hideTextbox()},
    "stop voice vo_04",
    "show background board1_3B with fadeIn duration 2s",
    "wait 500", 
    function(){updateTime(800)},
    function(){recordEvent("story_1_page_5_visited")},
    "show scene board1_4 with fadeIn",
    "play sound classroom with volume 20 fade 3",
    "wait 5000",
    "play voice vo_05",
    "Phew... at least Mei and I are on time today. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_05",
    function(){updateTime(810)},
    function(){recordEvent("story_1_page_6_visited")},
    // "show scene board1_5 with fadeIn",
    "show image pencil-background with fadeIn fullsize",
    "show image pencil with fadeIn fullsize pencil-animation",
    "play sound classroom with volume 5 fade 3",
    "play sound belly-blurp",
    "wait 800",
    "play voice vo_06",
    "I am so hungry. There's no breakfast for me today again. I can't concentrate. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_06",
    function(){recordEvent("story_1_page_7_visited")},
    "show scene board1_6 with notransition",
    "play voice vo_07",
    "I must stay focused! I cannot fall behind class again. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_07",
    function(){recordEvent("story_1_page_8_visited")},
    "show scene board1_7Ai",
    "show background board1_7Aii with notransition",
    "wait 500",
    "show background board1_7Aiii with notransition",
    "wait 500",
    "show background board1_7Aiv with notransition",
    "wait 500",
    "show background board1_7B with notransition",
    "play voice vo_08",
    "Look at his new uniform and shoes again! So white and clean. I wish I can have the same. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_08",
    function(){recordEvent("story_1_page_9_visited")},
    "show scene board1_8 with fadeIn",
    "play music sad-music with loop volume 20 fade 2",
    "play voice vo_09",
    "(sigh) Look at mine, yellow and crumpled. And so old. Even if I've time to iron my uniform. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_09",
    function(){recordEvent("story_1_page_10_visited")},
    "show scene board1_9 with fadeIn",
    "play voice vo_10",
    "I wish I have a new set of uniform.<br> But I must focus first. I must manage my time better. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_10",
    function(){hideTextbox()},
    "show background board1_3B with fadeIn",
    "jump reflection_1",
  ],
  reflection_1: [
    function(){recordEvent("reflection_1_visited")},
    "show background board2_1 with notransition",
    "show message reflection_1",
    "stop music sad-music with fade 5",
    "jump StoryPart_2",
  ],
  StoryPart_2: [
    function(){updateTime(900)},
    function(){recordEvent("story_2_page_1_visited")},
    "play sound bell_ring with volume 20 fade 2",
    "show scene board2_1 with fadeIn",
    "play voice vo_2_1",
    "We are getting back our Math results today. I didn't have time to study for this test. I already failed my English test, what do I tell Ma if I fail my Math too? <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_2_1",
    function(){hideTextbox()},
    function(){updateTime(902)},
    function(){recordEvent("story_2_page_2_visited")},
    "play sound class_people with volume 80 fade 2",
    "show scene board2_2a with fadeIn",
    "show background board2_2b with notransition",
    "wait 1000",
    "show background board2_2c with notransition",
    "wait 1000",
    "show background board2_2d with notransition",
    "wait 1000",
    function(){updateTime(910)},
    function(){recordEvent("story_2_page_3_visited")},
    "show scene board2_3",
    "stop sound class_people with volume 80 fade 2",
    "play sound paper_ruffling with volume 80 fade 2",
    "play voice vo_2_3",
    "Oh no. This isn't good. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_2_3",
    {
      "Choice": {
        "Dialog":"What should Ron do?",
        "FirstOption":{
          "Text": "See the teacher",
          "Do": "jump SeeTheTeacher"
        },
        "SecondOption":{
          "Text": "Leave class for recess with everyone else",
          "Do": "jump LeaveClass"
        }
      }
    }, 
  ],
  SeeTheTeacher: [
    function(){updateTime(1000)},
    function(){recordEvent("story_2_page_4a_visited")},
    "show scene board2_4ai", 
    "play sound marking with volume 40 fade 2",
    "play voice vo_2_4ai",
    "I hope Mr. Toh doesn't tell me off. He's always been nice to me. I hope he doesn't call Ma. The last thing I want is Ma upset or worried about me. Maybe he won't call Ma if I told him about home. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_2_4ai",
    function(){updateTime(1005)},
    "show scene board2_4aii",
    "play voice vo_2_4aii",
    "I hope Mr Toh will understand. I am really trying my best. But I just don't have time for myself.<br>Taking care of Mei, housework... <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_2_4aii",
    "stop sound marking",
    "play sound marking with volume 40 fade 2",
    "jump reflection_2",
  ],
  LeaveClass: [
    function(){updateTime(1000)},
    function(){recordEvent("story_2_page_4b_visited")},
    "show scene board2_4bi with fadeIn",
    "play voice vo_2_4bi",
    "Nevermind, it’s ok. I’ll just try harder next time. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_2_4bi",
    "show scene board2_4bii with fadeIn",
    "play sound marking with volume 40 fade 2",
    "play voice vo_2_4bii",
    "I really don't want to speak to Mr. Toh. How do I tell him that I don't have time like my friends? It's so hard to keep up with them. Ma and Pa have no time to help me with homework. I must get out of here quickly before he catches me. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_2_4bii",
    "stop sound marking",
    "play sound marking with volume 40 fade 2",
    "jump reflection_2",
  ],
  reflection_2: [
    function(){recordEvent("reflection_2_visited")},
    "show background board3_1 with notransition",
    "show message reflection_2",
    "jump StoryPart_3",
  ],
  StoryPart_3: [
    function(){hideTextbox()},
    function(){updateTime(1015)},
    function(){recordEvent("story_3_page_1_visited")},
    "play sound cafe with volume 20 with loop",
    "show scene board3_1 with fadeIn",
    "wait 2000",
    function(){updateTime(1020)}, 
    function(){recordEvent("story_3_page_2_visited")},
    "show background board3_2a with fadeIn",
    "play voice vo_3_2a",
    "Yay! Food!<br>So happy I can buy some food today! <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_3_2a",
    "show scene board3_2b with fadeIn", //with dreamy transition
    "[Ron starts to daydream] <img id='text-icon' src='./assets/images/end text icon.png'/>",
    function(){recordEvent("story_3_page_3_visited")},
    "show scene board3_3 with fadeIn",
    "stop sound cafe",
    "play sound football with volume 20 fadeOut",
    "play voice vo_3_3",
    "Raja said I might have a chance to go to my dream school if I attend this Friday's football trial. Something about Direct School Admission? Will I make it? Should I tell Ma about it? <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop sound football",
    "stop voice vo_3_3",
    {
      "Choice": {
        "Dialog":"What should Ron do?",
        "FirstOption":{
          "Text": "Skip the football trial and focus on my responsibilities",
          "Do": "jump SkipFootball"
        },
        "SecondOption":{
          "Text": "Ask Ma for help",
          "Do": "jump AskMa"
        }
      }
    }, 
  ],
  SkipFootball: [
    function(){updateTime(1020)},
    function(){recordEvent("story_3_page_4a_visited")},
    "show scene board1_0A with fadeIn",
    "play voice vo_3_4a",
    "If only I have some free time to join football. But who is going to take care of Mei and the house? (sigh) Maybe it will just be a dream.<br>Maybe I can try next year when Mei is older. I will have a chance then, right? <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_3_4a",
    "play sound football with volume 20",
    "jump reflection_3",
  ],
  AskMa: [
    function(){updateTime(1020)},
    function(){recordEvent("story_3_page_4b_visited")},
    "show scene board3_4b with fadeIn",
    "play sound football with volume 20 fadeOut",
    "play voice vo_3_4b",
    "Or can I ask Ma for help? But she’s so busy and stressed out too... (laments) <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_3_4b",
    "stop sound football",
    "play sound football with volume 20 fadeOut",
    "jump reflection_3",
  ],
  reflection_3: [
    function(){recordEvent("reflection_3_visited")},
    "show background board4_0 with notransition",
    "show message reflection_3",
    "jump StoryPart_4",
  ],
  StoryPart_4: [
    function(){updateTime(1300)},
    function(){recordEvent("story_4_page_1_visited")},
    "play sound school-bell with volume 80 fade 2",
    "show scene board4_0 with fadeIn",
    "[End of school day] <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop sound school-bell",
    function(){updateTime(1305)},
    function(){recordEvent("story_4_page_2_visited")},
    "show scene board4_1 with fadeIn",
    "play music children-ambience with volume 80 fade 2", 
    "play voice vo_4_1",
    "wait 500",
    "show background board4_2 with fadeIn",
    "wait 500",
    "Urgh...my head hurts and I'm feeling dizzy, I hope it's not a fever again.. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_4_1",
    function(){hideTextbox()},
    function(){recordEvent("story_4_page_3_visited")},
    "show scene board4_3a with fadeIn",
    "play sound gulp with volume 80",
    "wait 500",
    "show background board4_3b with notransition",
    "wait 500",
    "show background board4_3c with notransition",
    "play voice vo_4_3a",
    "It's ok - Water always works. I will just drink more until I find Mei and go home. Better hurry, I still need to cook lunch and do chores before I can do my homework. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop sound gulp",
    "stop voice vo_4_3a",
    "play music children-ambience with volume 60 fade 2",
    "jump reflection_4",
  ],
  reflection_4: [
    function(){recordEvent("reflection_4_visited")},
    "show background board5_0 with notransition",
    "show message reflection_4",
    "jump StoryPart_5",
  ],
  StoryPart_5: [
    function(){updateTime(1400)},
    function(){hideTextbox()},
    function(){recordEvent("story_5_page_0_visited")},
    "show scene board5_0 with fadeIn",
    "play sound open-door",
    "wait 2000",
    function(){recordEvent("story_5_page_1_visited")},
    "show scene board5_1 with fadeIn",
    "play sound bag-drop",
    "wait 200",
    "play voice vo_5_1",
    "Oh no, if only I did not oversleep this morning, I would have cleaned up. It will not be so messy.<img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop sound bag-drop",
    "stop sound open-door",
    "stop voice vo_5_1",
    function(){updateTime(1405)},
    function(){recordEvent("story_5_page_2_visited")},
    "show scene board5_2 with fadeIn",
    "play voice vo_5_2",
    "Oh ya...do I tell Ma about my results or football trials? (sigh) <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_5_2",
    function(){updateTime(1415)},
    function(){recordEvent("story_5_page_3_visited")},
    "show scene board5_3 with fadeIn",
    "play voice vo_5_3",
    "No no, I need to focus. It's already 2:15PM! Where should I start? <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_5_3",
    function(){recordEvent("story_5_page_4_visited")},
    "show scene board5_4 with fadeIn",
    "play voice vo_5_4",
    "And when Ma and Pa come back, there will be no space again. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_5_4",
    function(){updateTime(1420)},
    function(){recordEvent("story_5_page_5_visited")},
    "show scene board5_5 with fadeIn",
    "play sound munching with volume 80 fade 2",
    "wait 500",
    "play voice vo_5_5 with fadeIn",
    "You can do this Ron! Let me help Mei finish her lunch first. Then her homework. Once that is done, the space can be yours! <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop sound munching",
    "stop voice vo_5_5",
    function(){updateTime(1545)},
    function(){recordEvent("story_5_page_6_visited")},
    "show scene board5_6 with fadeIn",
    "play voice vo_5_6",
    "I better start on my chores, so I can finish early before Ma comes home.<br>Wait. Is Ma on AM or PM shift today? <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_5_6",
    //clock to animate to 10pm?
    "play music chore with volume 20 fade 2 with loop",
    function(){recordEvent("story_5_page_7_visited")},
    "show scene #E5E5E5 with fadeIn",
    function(){hideTextbox()},
    function(){changeBackgroundcolor()},
    "show image board5_7_1 with chore-animation1",
    "wait 2000",
    "show image board5_7_2 with chore-animation2",
    "wait 2000",
    "show image board5_7_3 with chore-animation3",
    "wait 2000",
    "show image board5_7_4 with chore-animation4",
    "wait 2000",
    "show image board5_7_5 with chore-animation5",
    "wait 2000",
    "show image board5_7_6 with chore-animation6",
    "wait 2000",
    "show image board5_7_7 with chore-animation7",
    "wait 2000",
    "show image board5_7_8 with chore-animation8",
    "play voice vo_5_7",
    "Ma said she was getting another job. That means she will be busier and I have to help out more. I hope I can manage... <img id='text-icon' src='./assets/images/end text icon.png'/>",
    
    "stop voice vo_5_7",
    function(){updateTime(2200)},
    function(){recordEvent("story_5_page_8_visited")},
    "show scene board5_8 with fadeIn",
    "play voice vo_5_8",
    "But we have so many things to pay for, so I have to help Ma and Pa.<img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_5_8",
    function(){hideTextbox()},
    "show scene board1_3B with fadeIn",
    "wait 300",
    "jump reflection_5",
  ],
  reflection_5: [
    function(){recordEvent("reflection_5_visited")},
    "show background board6_0 with notransition",
    "show message reflection_5",
    "stop music chore with fade 2",
    "jump StoryPart_6",
  ],
  StoryPart_6: [
    function(){updateTime(2300)},
    function(){recordEvent("story_6_page_0_visited")},
    "show scene board6_0 with fadeIn",
    "play sound open-door2",
    "play voice vo_6_0",
    "Ma and Pa are finally back.<br>Should I tell Ma anything now? <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_6_0",
    function(){recordEvent("story_6_page_1_visited")},
    "show scene board6_1 with fadeIn",
    "play voice vo_6_1",
    "Maybe not. I am so tired after all the chores and homework.<br>And I am sure Ma is tired too, and will be so disappointed with my Math result. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "play music beautiful-day with volume 70 fade 2 loop",
    "stop voice vo_6_1",
    function(){hideTextbox()},
    "show background board1_3B with fadeIn duration 2s",
    "wait 500",
    "show scene board3_3 with fadeIn",
    // "play music sad_to_upbeat",
    function(){recordEvent("story_6_page_2_visited")},
    "wait 200",
    "play voice vo_6_2",
    "I should sleep so I won't wake up late tomorrow.<br>I'm going to dream of playing football in my dream school (mind drifts to sleep) <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop music sad_to_upbeat",
    "stop voice vo_6_2",
    function(){hideTextbox()},
    function(){hideClock()},
    "show scene board1_0A with fadeIn",
    "wait 4000",
    "show background board6_3b with fadeIn 4",
    function(){recordEvent("story_6_page_3_visited")},
    "play voice vo_6_3a",
    "And this starts again tomorrow, perhaps with new and unexpected challenges. <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_6_3a",
    "show scene board6_4a with fadeIn",
    "wait 2000",
    "show background board6_4b with fadeIn",//with zoom effect
    function(){recordEvent("story_6_page_4_visited")},
    "play voice vo_6_4a",
    "What about you? How does your day look like? <img id='text-icon' src='./assets/images/end text icon.png'/>",
    "stop voice vo_6_4a",
    "wait 500",
    "jump reflection_6"
  ],
  reflection_6: [
    function(){showCreditButton()},
    function(){recordEvent("reflection_final_visited")},
    "show message reflection_6",
    "show message reflection_final",
    "stop music beautiful-day with fade 2s",
    "end",
  ],
});

import React, { useEffect } from "react";
import "@monogatari/core/dist/engine/core/monogatari.css";
import "./App.css";
import Monogatari from "@monogatari/core";
import SideBar from "./components/sidebar";
import QuickMenu from "./components/quickmenu";
import MuteButton from "./components/mutebutton";
import CreditScreen from "./components/credit-screen";
import MuteIngame from "./components/mute-ingame";
import Credits from "./components/credit-button";
import "./game/script";
import {recordEvent} from "./game/script";
import "./game/message";
import "./game/settings";
import "./game/assets";
import "./game/components";

function App() {
  useEffect(() => {
    Monogatari.registerComponent(QuickMenu);
    Monogatari.registerComponent(SideBar);
    Monogatari.registerComponent(MuteButton);
    Monogatari.registerComponent(CreditScreen);
    Monogatari.registerComponent(MuteIngame);
    Monogatari.registerComponent(Credits);
    Monogatari.init("#monogatari");
    recordEvent("titlepage_visited");
  }, []);
  
  return (
    <div className="App">
      <main-mobile className = "mainMobile">
        <div>
          <p>Start the Interactive Simulation Experience on Safari or Chrome!</p>
          <p>Please open in a new browser and rotate your device!</p>
        </div>
      </main-mobile>
      <div id="monogatari" className="monogatari">
        <visual-novel className="Game">
          <language-selection-screen></language-selection-screen>
          <loading-screen></loading-screen>
          <main-screen class="active">
            <main-menu></main-menu>
            <mute-button></mute-button>
          </main-screen>
          <game-screen>
            <sidebar-element></sidebar-element>
            {/* <mute-ingame></mute-ingame> */}
            <dialog-log></dialog-log>
            <text-box></text-box>
            <quickmenu-element></quickmenu-element>
            <credits-button></credits-button>
          </game-screen>
          <gallery-screen></gallery-screen>
          <credit-screen></credit-screen>
          <load-screen></load-screen>
          <save-screen></save-screen>
          <settings-screen ></settings-screen>
          <help-screen></help-screen>
        </visual-novel>
      </div>
    </div>
  );
}

export default App;


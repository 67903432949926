import Monogatari from "@monogatari/core";

Monogatari.component ('main-screen').template (() => {
  return `
      <div id="julie-tboy-logo">
        <img src="./assets/images/title-screen.jpg" alt=""/>
      </div>
  `;
});
Monogatari.component ('main-menu').template (() => {
  return `
      <button data-action="start" icon="" string="Begin Adventure" tabindex="0">
        <span class="undefined"></span>
        <span data-string="Begin Adventure">Begin Adventure</span>
        <img src="./assets/icons/Play.png" alt=""/>
      </button> 
      <button data-action="open-screen" data-open="load" icon="" string="Load Adventure" tabindex="0">
        <span class="undefined"></span>
        <span data-string="Load Adventure">Load Adventure</span>
        <img src="./assets/icons/Play.png" alt=""/>
      </button>
  `;
});
Monogatari.component ('settings-screen').template (() => {
  return `
      <button class="top left" data-action="back"></button>
          <h2 data-string="Settings">Settings</h2>
          <div class="row row--spaced padded text---center">
            <div class="row__column row__column--12 row__column--phone--12 row__column--phablet--12 row__column--tablet--6 row__column--desktop--6 row__column--desktop-large--6 row__column--retina--6">
              <div data-settings="audio" class="vertical vertical--center text--center" data-content="audio-settings">
                <h3 data-string="Audio">Audio</h3>
                <div class="slideContainer">
                  <span data-string="Music">Music Volume</span>
                  <input type="range" min="0.0" max="1.0" step="0.1" data-action="set-volume" data-target="music" value="1" id="musicRange" class="slider">
                  </div>
                  <div class="slideContainer">
                  <span data-string="Sound">Sound Volume</span>
                  <input type="range" min="0.0" max="1.0" step="0.1" data-action="set-volume" data-target="sound" value="1" id="soundRange" class="slider">
                  </div>
                  <div class="slideContainer">
                  <span data-string="Voice">Voice Volume</span>
                  <input type="range" min="0.0" max="1.0" step="0.1" data-action="set-volume" data-target="voice" value="1" id="voiceRange" class="slider">
                  </div>
              </div>
            </div>
            <div class="row__column row__column--12 row__column--phone--12 row__column--phablet--12 row__column--tablet--6 row__column--desktop--6 row__column--desktop-large--6 row__column--retina--6">
              <div data-settings="text-speed">
                <h3 data-string="TextSpeed">Text Speed</h3>
                <input type="range" min="1" max="50" step="1" data-action="set-text-speed">
              </div>
              <div data-settings="auto-play-speed">
                <h3 data-string="AutoPlaySpeed">Autoplay Speed</h3>
                <input type="range" min="0" max="60" step="1" data-action="set-auto-play-speed" data-content="auto-play-speed-controller">
              </div>
            </div>
          </div>
  `;
});
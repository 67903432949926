import { Component as Monogatari_Component } from "@monogatari/core";

class MuteIngame extends Monogatari_Component { 
  render() {
    return`
        <button data-action="back" string="Back" tabindex="0"">
          <img id="mute-ingame" src="./assets/images/VolumeOff-ingame.png" alt=""/>)
        </button>
        `;
  }
}

MuteIngame.tag = "mute-ingame";

export default MuteIngame;

import { Component as Monogatari_Component } from "@monogatari/core";
import { $_ } from '@monogatari/core';
import Monogatari from "@monogatari/core";

class MuteButton extends Monogatari_Component {
  constructor () {
		super();
		this.sound = "VolumeOn"
	}
  
  didMount () {
      $_(this).find ('button').click ((event) => {
        var image = document.getElementById('mute'); 
        let sound = "VolumeOff"
        if (image.src.includes(sound) ){
          Monogatari.playAmbient();
          image.src = window.location+"./assets/images/VolumeOn.png";
        } else {
          Monogatari.stopAmbient();
          image.src = window.location+"./assets/images/VolumeOff.png";
        }
      });
    
    return Promise.resolve ();
  }

  render() {
    return `
        <button type="button">
          <img id="mute" src="./assets/images/${this.sound}.png" alt=""/>
        </button>
    `;
  }
}

MuteButton.tag = "mute-button";

export default MuteButton;

import { Component as Monogatari_Component } from "@monogatari/core";

class Credits extends Monogatari_Component {
    render() {
      return `<p id=game-credit-p data-action="open-screen" data-open="credit" icon="undefined" string="Credit" tabindex="0">
      <span class="undefined"></span>
      <span data-string="Credit">GAME CREDITS</span><img id='game-credits-icon' src='./assets/images/game-credits-icon.png'/></p>
      `;
    } 
  }

Credits.tag = 'credits-button';

export default Credits



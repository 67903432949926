import { Component as Monogatari_Component } from "@monogatari/core";

class SideBar extends Monogatari_Component {
  render() {
    return `<div id="clock" >
                <img id="clock_img" src="./assets/images/600.png"/>
            </div>`;
  }
}

SideBar.tag = 'sidebar-element';

export default SideBar
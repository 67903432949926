import { Component as Monogatari_Component } from "@monogatari/core";


class CreditScreen extends Monogatari_Component {
  constructor () {
		super();

		this.state = {
			open: false
		};
	}

	willMount () {
		this.dataset.screen = this.constructor.tag.replace ('-screen', '');
		return Promise.resolve ();
	}

	onStateUpdate (property, oldValue, newValue) {
		if (property === 'open') {
			if (newValue === true) {
				this.classList.add ('active');
			} else {
				this.classList.remove ('active');
				document.getElementById("game-credit-p").style.display="none"
				document.getElementById("clock").style.animation="none"
				document.getElementById("sidemenu").style.animation="none"
			}
		}
		return Promise.resolve ();
	}
	render () {
		return `
          <button class="animate active" data-action="end"><span>BACK TO MAIN MENU</span></button>
          <h2 data-string="Credits" data-content="title">Credits</h2>
          <div data-content="credits" data-ui="credits">
					<div id=splashscreen >
					<img id="second-page" src="./assets/images/second-page.png" alt="" />
					<img id=first-page src="./assets/images/first-page.png" alt="" />
					</div>
            <h3>Music and Sound Effects</h3>
            <div>
              <p>Sound effects obtained from:<br><a href="https://www.zapsplat.com/">https://www.zapsplat.com/</a></p>
              <p>Sound effect obtained from <a href="https://freesound.org">https://freesound.org</a>:</p>
              <p>John_sauter</p>
              <p>Music used from <a href="https://www.FesliyanStudios.com">https://www.FesliyanStudios.com</a>:</p>
              <p>Art of silence by uniq</p>
            </div>
            <h3>Playtesters</h3>
						<div class="row">
							<div class="column">
								<p>Calvin Tan</p>
								<p>Hana Shafiq</p>
								<p>Jedidiah Sim</p>
								<p>Julianne Lim</p>
								<p>Muhammad Aysh</p>
							</div>
							<div class="column">
								<p>Chua Jia Wei</p>
								<p>Rachel Ong</p>
								<p>Rebecca Yew</p>
								<p>Tan Caiyun</p>
								<p>Tamara Chin</p>
							</div>
						</div> 
        </div>
				
				

		`;
	}
	
}

CreditScreen.tag = 'credit-screen';


export default CreditScreen;


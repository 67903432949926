import { Component as Monogatari_Component } from "@monogatari/core";
// import moment from 'moment/min/moment-with-locales';
// import { $_ } from '@aegis-framework/artemis';

class QuickMenu extends Monogatari_Component {

  // static bind (selector) {
  //   this.instances ().on ('click', '[data-component="save-slot"]', () => {
	// 		const slotName = "AutoSave";
	// 		if (slotName !== '') {
	// 			this.engine.loadFromSlot($_(this).attribute ('slot')).then (() => {
  //         this.engine.run (this.engine.label ()[this.engine.state ('step')]);
  //       });
	// 		}
	// 	});
	// 	return Promise.resolve ();
	// }

	// onStateUpdate (property, oldValue, newValue) {
	// 	super.onStateUpdate (property, oldValue, newValue);
	// 	if (property === 'open' && newValue === true) {
	// 		this.content ('slot-name').value (moment ().format ('LL LTS'));
	// 	}
	// 	return Promise.resolve ();
	// }
    render() {
      return `
      <div id="sidemenu">
      <button data-action="open-screen" data-open="save" string="Save" tabindex="0">
        <img src="./assets/icons/Paper Upload.svg" alt=""/>
        <span data-string="Save">Save</span>
      </button>
      <button data-action="open-screen" data-open="load" string="Load" tabindex="0">
        <img src="./assets/icons/Download.svg"alt=""/>
        <span data-string="Load">Load</span>
      </button> 
      <button data-action="open-screen" data-open="settings" string="Settings" tabindex="0">
        <img src="./assets/icons/Setting.svg" alt=""/>
        <span data-string="Settings">Settings</span>
      </button> 
      <button data-action="end" string="Quit" tabindex="0">
        <img src="./assets/icons/Close.svg" alt=""/>
        <span data-string="Quit">Quit</span>
      </button>
      </div>
    `;
    } 
  }

QuickMenu.tag = 'quickmenu-element';

export default QuickMenu

/* <button data-action="back" string="Back" tabindex="0">
        <img src="./assets/icons/Arrow-Left.svg" alt="" />
        <span data-string="Back">Back</span>
      </button>
      <button data-action="auto-play" string="AutoPlay" tabindex="0">
        <img src="./assets/icons/Play.svg" alt=""/>
        <span data-string="AutoPlay">Auto</span>
      </button>  */
import Monogatari from "@monogatari/core";

Monogatari.settings({
  Name: "Visual Novel App",
  MultiLangauge: false,
  ServiceWorkers: false,
  OrientationWarning: 'Please open in a new browser and rotate your device to play',
  AssetsPath: {
    root: "./assets",
    characters: "characters",
    fonts: "fonts",
    gallery: "gallery",
    icons: "icons",
    images: "images",
    music: "music",
    scenes: "scenes",
    sounds: "sounds",
    ui: "ui",
    videos: "videos",
    voices: "voices",
  },
  'Preload': true,
  'ForceAspectRatio': 'none',
  'Orientation': 'landscape',
  'MainScreenMusic': 'background-music',
  'Label': 'Start',
  'Slots': 10,
  'ShowMainScreen': true,
  'Skip': 0,
  // 'AutoSaveLabel': 'AutoSave',
  // 'AutoSave': 0.5,
});
Monogatari.preferences ({
  'Language': 'English',
  'Volume': {
    'Music': 1,
    'Voice': 0.8,
    'Sound': 1,
    'Video': 1
  },
  'TextSpeed': 30,
  'AutoPlaySpeed': 5
});
Monogatari.storage ({
  player: {
    name: '',
    time: 600
  }
});

import Monogatari from "@monogatari/core";

Monogatari.action ('Message').messages ({
  'reflection_1': {
      title: `<img id=rice src="./assets/images/rice.png">`,
      subtitle:'Many poor families barely have enough money to meet their survival needs such as rent, household bills, and food.<br> <br>Often, they are unable to afford textbooks, uniforms, stationery and pocket money for their children. As a result, they may be hungry and face difficulty concentrating in class. ',
      body: 'How do we ensure no one<br>gets left behind?'
  },
  'reflection_2': {
    title: `<img id=books-img src="./assets/images/books.png">`,
    subtitle: 'Many children in Singapore have comfortable homes and parents who will help them with their studies. Some children also attend tuition classes to support their studies. However, children like Ron may not get help from their parents and often do not have time or money to attend tuition classes.',
    body: 'Find out how can we better equip children like Ron at the end of this story.'
  },
  'reflection_3': {
    title: `<img id=football src="./assets/images/football.png">`,
    subtitle: `
        <div id=ref_3_sub>
          <p>Co-curricular activities (CCAs) improve students’ educational and life skills. CCAs also teach leadership, teamwork and social skills. They also help with Direct School Admission allowing students to secure a place at their desired school before PSLE results are released.</p> 
          <p>However, students from poor families may not be able to attend CCAs as it takes up a lot of time and money. As a result, these students may miss out on important opportunities for their development.</p>
        </div>
        `,
    body: ''
  },
  'reflection_4': {
    title: `<img id=waterbottle src="./assets/images/waterbottle.png">`,
    subtitle: 'Children like Ron have to juggle many immediate responsibilities such as caring for their siblings, household chores, and schoolwork. They are also aware of their family’s financial struggles; they know that medical costs are high, and don’t want to make things more difficult for their family. Finances are tight for families living in urban poverty. It is a constant balancing act, with putting food on the table as the top priority.',
    body: 'Taking care of their health is often the last thing on their minds.'
  },
  'reflection_5': {
    title: `<img id=clock-img src="./assets/images/clock.png">`,
    subtitle: 'Many children like Ron are aware of their families’ financial hardship. These children are forced to “grow up faster”, and pick up important life skills that help them juggle many responsibilities ata very young age. They often put their family’s needs before theirs because they understand that money is hard to come by.',
    body: 'What can we do to provide better opportunities for children like Ron?'
  },
  'reflection_6': {
    
    title: `<img id=ref_6_title src="./assets/images/mini-timetable.png">`,
    subtitle: '<div id=ref_6_subtitle>Could you have managed Ron’s timetable?</div>',
    body: `
      <div id=ref_6_p>
        <p>Is this how urban poverty might look to you? </p>
        <p>1 in 10 Singaporeans are unable to meet basic needs in the form of food, clothing, shelter and other essential expenditures.</p>
        <p>Ron could be anyone in our lives. We might not know their situations, but we can do our part in becoming the support they need.</p> 
        <p>Explore these options below and join the community that inspires action to uplift many others like Ron and his family, who struggles with urban poverty in Singapore.</p> 
      </div> 
      `
  },
  'reflection_final':{
    title: `<img id=ref_6_title src="./assets/images/mini-timetable.png">`,
    subtitle: '<div id=ref_6_subtitle>Could you have managed Ron’s timetable?</div>',
    body: `
        <p id=ref_6_p>Explore these options below and join the community that inspires action to uplift many others like Ron and his family, who struggle with urban poverty in Singapore.</p>
        <div id= ref_6_button_div>
        <p><a href="https://www.the-best-of-you.com/resource-hub/urban-poverty" target="_blank">Learn More</a> and educate yourself with these resources</p>
        <p><a href="https://www.the-best-of-you.com/resource-hub/partners" target="_blank">Pledge Your Support</a> and offer your help with these programs</p>
        </div>
    `
  }
});




import Monogatari from "@monogatari/core";

Monogatari.assets ('music', {
  'background-music': 'loading screen.mp3',
  'sad-music': 'sad-music2.mp3',
  'children-ambience':'4.1-4.3 children ambience.mp3',
  'chore':'5.7.new-spruce-and-pine.mp3',
  'beautiful-day':'6.3A onwards some-beautiful-day-30-sec-edit.mp3',
  'vo_01': '210518 Design Kloo_Slide1v1_option1.mp3',
});

Monogatari.assets("sounds", {
  "alarm-clock": "1.0A alarm clock.mp3",
  "quick-footsteps": "1.2. layered footsteps.mp3",
  'watering': "1.2 watering-can_06.mp3",
  "elevator-opening": "1.3A elevator opens.mp3",
  "elevator-close": "1.3A end scene_elevator closes.mp3",
  "classroom": "1.4 layered classroom.mp3",
  "belly-blurp": "1.5 belly-blurp_edited_.mp3",
  "bell_ring": "2.1.bell-ring.mp3",
  "class_people": "2.2 classroom ambience and chair.mp3",
  "paper_ruffling":"2.3 paper and class ambience.mp3",
  "marking":"2.4 classroom and chairs and marking.mp3",
  'cafe':'testing_cafeteria.mp3',
  'football':'3.3 and 3.4 football.mp3',
  'school-bell':'4.0. free sound_john-sauter__electric-school-bell.mp3',
  'gulp':'4.3 drinking gulp_edit.mp3',
  'bag-drop':'5.0 bag drop 2s.mp3',
  'open-door':'5.0 open door first2s.mp3',
  'munching':'5.5. zapsplat eating scene.mp3',
  'open-door2':'6.0 open door first2s.mp3',
});

Monogatari.assets ('voices', {
  'vo_01': '210518 Design Kloo_Slide1v1_option1.flac',
  'vo_02': '210518 Design Kloo_Slide2v1_option2.flac',
  'vo_03': '210518 Design Kloo_Slide3v1_option1.flac',
  'vo_04': '210518 Design Kloo_Slide4v1_option3.flac',
  'vo_05': '210518 Design Kloo_Slide5-10v1_option2_board1_4.flac',
  'vo_06': '210518 Design Kloo_Slide5-10v1_option2_board1_5.flac',
  'vo_07': '210518 Design Kloo_Slide5-10v1_option2_board1_6.flac',
  'vo_08': '210518 Design Kloo_Slide5-10v1_option2_board1_7_without_raja.flac',
  'vo_09': '210518 Design Kloo_Slide5-10v1_option2_board1_8.flac',
  'vo_10': '210518 Design Kloo_Slide5-10v1_option2_board1_9.flac',
  'vo_2_1':'210518 Design Kloo_Slide12v1_option1.flac', 
  'vo_2_3':'210518 Design Kloo_Slide14v1_option3.flac', 
  'vo_2_4ai':'210518 Design Kloo_Slide16v1_option3.flac',
  'vo_2_4aii':'210518 Design Kloo_Slide18v1_option1.flac',
  'vo_2_4bi':'210518 Design Kloo_Slide20v1_option3.flac',
  'vo_2_4bii':'210518 Design Kloo_Slide22v1_option1.flac',
  'vo_3_2a':'210518 Design Kloo_Slide25-26v1_Option1_3_2A.flac',
  'vo_3_3':'210518 Design Kloo_Slide25-26v1_Option1_3_3.flac',
  'vo_3_4a':'210518 Design Kloo_Slide28v1_Option3_with2ndPara_3_4A.flac',
  'vo_3_4b':'210518 Design Kloo_Slide28v1_Option3_3_4B.flac',
  'vo_4_1':'210518 Design Kloo_Slide33v1_Option3_4_1.flac',
  'vo_4_3a':'210518 Design Kloo_Slide35v1_Option3_4_3A.flac',
  'vo_5_1':'210518 Design Kloo_Slide38v1_Option4_5_1.flac',
  'vo_5_2':'210518 Design Kloo_Slide39-40v1_Option3_5_2.flac',
  'vo_5_3':'210518 Design Kloo_Slide39-40v1_Option2_5_3.flac',
  'vo_5_4':'210518 Design Kloo_Slide39-40v1_Option3_5_4.flac',
  'vo_5_5':'210518 Design Kloo_Slide43v1_Option3_5_5.flac',
  'vo_5_6':'210518 Design Kloo_Slide45v1_Option2_5_6.flac',
  'vo_5_7':'210518 Design Kloo_Slide45v1_Option3_5_7.flac',
  'vo_5_8':'210518 Design Kloo_Slide47v1_Option2_5_8.flac',
  'vo_6_0':'210518 Design Kloo_Slide48v1_Option3_6_0.flac',
  'vo_6_1':'210518 Design Kloo_Slide48v1_Option3_6_1.flac',
  'vo_6_2':'210518 Design Kloo_Slide50v1_Option3_6_2.flac',
  'vo_6_3a':'210518 Design Kloo_Slide50-52v1_Option2_6_3A.flac',
  'vo_6_4a':'210518 Design Kloo_Slide52v1_Option2_6_4A.flac',
});

Monogatari.assets("images", {
  "palo-logo": "second-page.jpg",
  "julie-logo": "first-page.jpg",
  "kid": "kid_steps_10.png",
  "plant_women":"plant and malay.png",
  'board5_7_1':"5_7_img1.jpg",
  'board5_7_2':"5_7_img2.jpg",
  'board5_7_3':"5_7_img3.jpg",
  'board5_7_4':"5_7_img4.jpg",
  'board5_7_5':"5_7_img5.jpg",
  'board5_7_6':"5_7_img6.jpg",
  'board5_7_7':"5_7_img7.jpg",
  'board5_7_8':"5_7_img8.jpg",
  'pencil':'06.png',
  'pencil-background':'06_test.jpg',
  'hand':'01_hand.png',
  'hand-background':'01_background.jpg',
  'testing_board':'1_1.jpg',
  'testing_board2':'1_2.jpg'
});

Monogatari.assets("scenes", {
  "palo-logo": "second-page.jpg",
  "julie-logo": "first-page.jpg",
  'board1_0A': "1_0A.jpg",
  'board1_0B': "1_0B.jpg",
  'board1_1': "1_1.jpg",
  'board1_2': "nopeople_Corridor.jpg",
  'board1_3A': "1_3A.jpg",
  'board1_3B': "1_3B.jpg",
  'board1_4': "1_4.jpg",
  'board1_5': "06.jpg",
  'board1_6': "1_6.jpg",
  'board1_7Ai': "1_7Ai.jpg",
  'board1_7Aii': "1_7Aii.jpg",
  'board1_7Aiii': "1_7Aiii.jpg",
  'board1_7Aiv': "1_7Aiv.jpg",
  'board1_7B': "1_7B.jpg",
  'board1_8': "1_8.jpg",
  'board1_9': "1_9.jpg",
  'board2_1': "12.jpg",
  'board2_2a': "13a.jpg",
  'board2_2b': "13b.jpg",
  'board2_2c': "13c.jpg",
  'board2_2d': "13d.jpg",
  'board2_3': "14.jpg",
  'board2_4ai': "15.jpg",
  'board2_4aii': "16.jpg",
  'board2_4bi': "17.jpg",
  'board2_4bii': "15a.jpg",
  'board3_1':"18.jpg",
  'board3_2a':"19.jpg",
  'board3_2b':"19b.jpg",
  'board3_3':"20.jpg",
  'board3_4b':"20.jpg",
  'board4_0':"23.jpg",
  'board4_1':"24.jpg",
  'board4_2':"25.jpg",
  'board4_3a':"26a.jpg",
  'board4_3b':"26b.jpg",
  'board4_3c':"26c.jpg",
  'board5_0':"27.jpg",
  'board5_1':"28.jpg",
  'board5_2':"30b.jpg",
  'board5_3':"30.jpg",
  'board5_4':"29.jpg",
  'board5_5':"31.jpg",
  'board5_6':"32.jpg",
  'board5_7':'board5_7.jpg',
  'board5_8':"45.jpg",
  'board6_0':"41.jpg",
  'board6_1':"41a.jpg",
  'board6_3b':"42.jpg",
  'board6_4a':"43.jpg",
  'board6_4b':"44.jpg",
});